import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { history } from "../../managers/history";
import { sessionManager } from "../../managers/sessionManager";
import Utils from "../../utility";

const PlanSwitch = ({ checkedPlanType, onCheck }) => (
  <div className="flex items-center font-PoppinsRegular text-ft14-21 text-white gap-2.5 mt-8.5">
    <p>Bill Monthly</p>
    <label className="switch w-17 h-8 inline-block relative">
      <input
        type="checkbox"
        className="hidden"
        onChange={onCheck}
        checked={checkedPlanType === "yearly"}
      />
      <span
        className={`slider absolute cursor-pointer rounded-4.5 top-0 bottom-0 left-0 right-0 ${
          checkedPlanType === "yearly" ? "bg-red-10" : "bg-gray-10"
        }`}
      ></span>
    </label>
    <p>Bill Yearly</p>
  </div>
);

const PlanCard = ({
  plan,
  isYearDuration,
  isFreePlan,
  onSubscribe,
  hasCurrentPlan,
  handleSubscribeUnuser,
  userLoggedIn,
}) => {
  const buttonClass = getButtonClass();

  function getButtonClass() {
    if (hasCurrentPlan) {
      return isFreePlan
        ? "text-gray-100 mb-0 mt-10.5 bg-gray-70"
        : "text-gray-100 mb-0 mt-10.5 bg-white";
    } else {
      if (isFreePlan) {
        return "text-white hover:text-black-0 hover:bg-white bg-gray-70 mt-10.5";
      } else {
        return "text-red-10 hover:text-white bg-white border-none lg:mt-10 hover:bg-gray-70";
      }
    }
  }

  const buttonText = hasCurrentPlan ? "Current plan" : "Subscribe";

  const handleButtonClick = () => {
    if (userLoggedIn) onSubscribe(plan);
    else handleSubscribeUnuser(plan);
  };

  return (
    <div className="flex flex-row ml-8 md:ml-0">
      <div
        className={`md:w-487px w-90per md:h-176 h-auto pb-4 md:px-10 md:pt-8.5 pt-4 px-5 bg-white rounded-xl shadow-lg ${
          isFreePlan ? "bg-gray-70 text-white" : "bg-red-10 text-white"
        }`}
      >
        <div className="flex justify-between">
          <h2
            className={`text-ft20-37 flex justify-start font-PoppinsMedium pb-2 ${
              isFreePlan ? "hidden" : " text-white"
            }`}
          >
            {plan?.planName}
          </h2>
          {isYearDuration && (
            <div className="border-t border-l border-b bg-white px-4 h-9.5 rounded-l-3xl text-red-10 text-ft18-27 text-center pt-1 pb-1 md:-mr-10 -mr-5 font-PoppinsMedium">
              Most popular
            </div>
          )}
        </div>
        <h3 className="text-ft20-37 flex justify-start font-PoppinsSemiBold mt-4">
          <span
            className={`md:text-ft70-50 text-ft41-50 ${
              isFreePlan ? "mt-5" : ""
            }`}
          >
            {isFreePlan
              ? "Free"
              : Utils.formatCurrency(plan?.price, plan?.currency)}
          </span>
          <span className="flex items-end font-PoppinsMedium text-ft20-37">
            {isFreePlan ? "" : "/" + plan?.duration}
          </span>
        </h3>
        {isYearDuration && (
          <div className="pt-2 font-PoppinsMedium">{plan?.description}</div>
        )}
        <div
          className={`h-px ${
            isFreePlan ? "bg-gray-80" : "bg-red-40"
          } md:mt-2 md:mb-2 my-2`}
        ></div>
        <div
          className={`flex justify-start ${
            isFreePlan ? "md:h-92.5" : "md:h-92"
          }`}
        >
          <div className={`text-black-20 text-ft16-22 font-PoppinsRegular`}>
            {plan?.features?.map((feature, idx) => (
              <p
                className={`text-left flex items-center mx-2 font-PoppinsRegular ${
                  plan?.currentPlan === "Free" ? "text-gray-100" : ""
                } text-ft16-22 md:my-3 my-1 text-white`}
                key={idx}
              >
                <img
                  className="mr-3 mb-1 h-3.5 w-19px"
                  src="/images/tick-icon.svg"
                  alt=""
                />
                <span className="">{feature?.name}</span>
              </p>
            ))}
          </div>
        </div>
        <button
          disabled={hasCurrentPlan}
          onClick={handleButtonClick}
          className={`${buttonClass} md:h-18.5 md:w-101 md:text-ft18-27 text-ft14-21 w-auto font-PoppinsSemiBold border font-bold py-3 px-6 my-4 rounded-xl`}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

const SubscriptionPlan = ({
  onSubscribe,
  subcriptionPlans,
  checkedPlanType,
  onCheck,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.user);

  const handleSubscribeUnuser = (data) => {
    const isPaidPlan = data?.planType?.toLowerCase() === "paid";
    if (isPaidPlan) {
      sessionManager.setDataInCookies("REDIRECT_LINK", "/subscription-plan", 1);
    }
    history.push("/login");
  };

  return (
    <div className="flex flex-col items-center text-center bg-black-0 pt-15 md:pb-72 pb-5">
      <h1 className="md:text-ft46-50 text-ft36-50 font-TimesBold text-white">
        Convenient Pricing
      </h1>
      <span className="text-white md:text-ft20-37 text-ft17-26 mt-2 w-5/6 md:w-auto font-PoppinsMedium">
        Choose the right pricing for you and get started.
      </span>
      <PlanSwitch checkedPlanType={checkedPlanType} onCheck={onCheck} />
      <div className={`flex flex-col lg:flex-row flex-wrap mt-6 gap-2.5`}>
        {subcriptionPlans?.plans?.[checkedPlanType]?.map((plan, index) => {
          const isFreePlan = plan?.planType?.toLowerCase() === "free";
          const isYearDuration = plan?.duration === "year";
          const hasCurrentPlan = plan?.currentPlan;

          return (
            <PlanCard
              key={index}
              plan={plan}
              isFreePlan={isFreePlan}
              isYearDuration={isYearDuration}
              hasCurrentPlan={hasCurrentPlan}
              onSubscribe={onSubscribe}
              handleSubscribeUnuser={handleSubscribeUnuser}
              userLoggedIn={user.isLoggedIn}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionPlan;
