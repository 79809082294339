import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";
import { Auth0Service, UserService,SubscriptionService,StoryService} from "../../services";
import { errorMessagesConstants ,actionTypeConstants} from "../../constants";
import CreateSubscription from "../../services/subscriptionService";
import { sessionManager } from "../../managers/sessionManager";
import { history } from "../../managers/history";
import Utils from "../../utility";

const {
  EMAIL_REQUIRED,
  PASSWORD_REQUIRED,
  PASSWORD_MUST_8_CHARACTERS,
  PASSWORD_ALPHA_NUMBER_ERROR,
  INVALID_EMAIL,
} = errorMessagesConstants;
function CreateAccount() {
  const [isLoading, setIsloading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signUpError, setSignUpError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { accepted: false } });

  const { UPDATE_USER, SET_USER_PLAN } = actionTypeConstants;

  const dispatch = useDispatch();

  const saveUserHandler = async (data) => {
    try {
      await new UserService().saveUser(data);
    } catch (err) {}
  };

  const getUserPlan = async (userId) => {
    try {
      return await new SubscriptionService().getSubscribedPlans({
        userId,
      });
    } catch (error) {
      return {};
    }
  };

  const getPlans = async () => {
    try {
      return await new CreateSubscription().getPlans();
    } catch (error) {
      return {};
    }
  };

  const SubscriptionPlan = async (userId, planId, planType) => {
    try {
      const response = await new CreateSubscription().subscribePlan({
        userId,
        planId,
        planType,
      });
      return response;
    } catch (error) {
      return {};
    }
  };

  const redirectToLink = (planType) => {
    const redirectLink = sessionManager.getDataFromCookies("REDIRECT_LINK");
    if (redirectLink) {
      sessionManager.removeDataFromCookies("REDIRECT_LINK");
      if (redirectLink === "/story-book/home" && planType === "paid") {
        history.push("/story-book/create-book");
      } else {
        history.push(redirectLink);
      }
    } else {
      history.push("/");
    }
  };

  const onSubmit = async (data) => {
    setIsloading(true);
    try {
      const response = await new Auth0Service().signUp(data);
      await saveUserHandler({
        name: response.name,
        userId: `auth0|${response.Id}`,
        email: response.email,
        firstName: data.firstName,
        lastName: data.lastName,
      });

      const signInData = {
        email: data.email,
        password: data.password,
      };
  
      if(response){
      try {
        const responseData = await new Auth0Service().signIn(signInData);

        const userData = {
          name: responseData.userInfoRes.nickname,
          firstName: responseData.userInfoRes.given_name,
          lastName: responseData.userInfoRes.family_name,
          userId: responseData.userInfoRes.sub,
          email: responseData.userInfoRes.email,
          profilePicture: responseData.userInfoRes.picture,
        };
        await saveUserHandler(userData);
       
        let [userDetails, userPlan] = await Promise.all([
          new UserService().getUserData({
            userId: responseData.userInfoRes.sub
          }),
          getUserPlan(responseData.userInfoRes.sub)
        ]);
        dispatch({
          type:UPDATE_USER,
          payload: {
            ...userData,
            profilePicture:!!userDetails.profilePicture
            ? userDetails.profilePicture
            :responseData.userInfoRes.picture,
            userIntro: userDetails?.userIntro || "",
            _id: userDetails?._id,
            role:userDetails?.role,
          },
        });
        history.push("/")
        if (
          userPlan?.status === "NO_VALID_PLAN" ||
          userPlan?.status === "EXPIRED"
        ) {
          const plans = await getPlans();
          const planIndex = plans.findIndex((plan) => plan.planType === "Free");
          userPlan = await SubscriptionPlan(
            responseData.userInfoRes.sub,
            plans[planIndex]._id,
            plans[planIndex].planType
          );
        }

        const storyConfig = sessionManager.getDataFromCookies("story_config");
        if (!storyConfig) {
          if (userPlan?.status === "EXPIRED") {
            dispatch({
              type: SET_USER_PLAN,
              payload: {
                hasPlan: false,
                expired: true,
                isLoading: false,
                planDetails: {},
              },
            });
          } else {
            dispatch({
              type: SET_USER_PLAN,
              payload: {
                hasPlan: true,
                expired: false,
                isLoading: false,
                planDetails: userPlan,
              },
            });
          }
          redirectToLink(userPlan?.planType?.toLowerCase());
          return;
        }
        checkPlanStatus({ storyConfig, subscriptionPlan: userPlan, userDetails });
      } catch (error) {
        console.log(error);
        setSignUpError("Error signing in");
      }
    }
    } catch (error) {
      console.log(error);
      setSignUpError("Email address already exist");
    } finally {
      setIsloading(false);
    }
  };


  const createStory = async (request, userDetails) => {
    try {
      const query = new URLSearchParams(request).toString();
      const [[story], createRes] = await Promise.all([
        new StoryService().getStory(query),
        new StoryService().createStory({
          ...request,
          userId: userDetails.userId,
          characterNamedAs: request.name,
        }),
      ]);
      if (!story || !story?.storyParagraphs || !createRes?.amqResponse)
        throw new Error("");
      const paragraphs = Utils.organizeParagraph(
        story.storyParagraphs,
        story.images
      );
      dispatch({
        type: "UPDATE_STORY",
        payload: {
          paragraphs,
          ...story,
          storyConfig: {
            ...request,
            fetchData: true,
            storyId: createRes?.storyId,
          },
        },
      });

      Utils.navigateToStory(story?._id, story?.storyTitle);
    } catch (error) {
      Utils.failureToast(error?.message, {
        className: "font-PoppinsMedium",
        style: {
          borderRadius: "16px",
          height: "68px",
          padding: "23px 22px 22px 18px",
        },
      });
      console.log("error: ", error);
      setTimeout(() => {
        history.push("/");
      }, 1500);
    } finally {
      sessionManager.removeDataFromCookies("story_config")
    }
  };


  function checkPlanStatus({ storyConfig, subscriptionPlan, userDetails }) {
    if (subscriptionPlan?.status === "NO_VALID_PLAN") {
      history.push("/subscription-plan");
      dispatch({
        type: SET_USER_PLAN,
        payload: { hasPlan: false, expired: false, planDetails: {} },
      });
      return;
    }
    if (subscriptionPlan?.status === "EXPIRED") {
      dispatch({
        type: SET_USER_PLAN,
        payload: { hasPlan: false, expired: true, planDetails: {} },
      });
      Utils.failureToast(
        "Your plan has been expired, Please upgrade your plan!",
        {
          className: "font-PoppinsMedium",
          style: {
            borderRadius: "16px",
            height: "68px",
            padding: "23px 22px 22px 18px",
          },
        }
      );
      setTimeout(() => {
        history.push("/subscription-plan");
      }, 1500);
      return;
    }

    const isUnlimited =
      String(subscriptionPlan?.featureBalances?.AIStory)?.toLowerCase() ===
      "unlimited";
    if (isUnlimited) {
      createStory(storyConfig, userDetails);
      dispatch({
        type: SET_USER_PLAN,
        payload: {
          hasPlan: true,
          expired: false,
          planDetails: subscriptionPlan,
        },
      });
      return;
    }

    if (
      Number(subscriptionPlan?.featureBalances?.AIStory) < 1 &&
      Number(subscriptionPlan?.dailyCredits.AIStory) < 1
    ) {
      Utils.failureToast(errorMessagesConstants.LIMIT_EXCEEDED, {
        className: "font-PoppinsMedium",
        style: {
          borderRadius: "16px",
          height: "68px",
          padding: "23px 22px 22px 18px",
        },
      });
      dispatch({
        type: SET_USER_PLAN,
        payload: {
          hasPlan: true,
          expired: false,
          planDetails: subscriptionPlan,
        },
      });
      setTimeout(() => {
        history.push("/subscription-plan");
      }, 1500);
      return;
    }
    createStory(storyConfig, userDetails);

    const planDeductedBalance =
      Number(subscriptionPlan?.dailyCredits?.AIStory) < 1
        ? {
            ...subscriptionPlan,
            featureBalances: {
              ...subscriptionPlan?.featureBalances,
              AIStory:
                Number(subscriptionPlan?.featureBalances?.AIStory || 0) - 1,
              audioBook:
                Number(subscriptionPlan?.featureBalances?.audioBook || 0) - 1,
            },
          }
        : {
            ...subscriptionPlan,
            dailyCredits: {
              ...subscriptionPlan?.dailyCredits,
              AIStory: Number(subscriptionPlan?.dailyCredits?.AIStory || 0) - 1,
              audioBook:
                Number(subscriptionPlan?.dailyCredits?.audioBook || 0) - 1,
            },
          };

    dispatch({
      type: SET_USER_PLAN,
      payload: {
        hasPlan: true,
        expired: false,
        planDetails: planDeductedBalance,
      },
    });
  }



  const handleOAuthSignIn = () => {
    new Auth0Service().oAuthSignIn();
    setIsSignUp(true);
  };

  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15  pb-14 ">
        <h1 className="sm:text-ft46-50 text-ft30-37     items-center text-center font-TimesBold mb-1.5 text-white">
          Create a new account
        </h1>
        <h3 className="sm:text-ft17-26 text-center text-ft1 lg:px-40 md:px-24 px-12 max-w-310 mx-auto font-PoppinsMedium pb-11.5  text-white">
          Become a co-author to save, share and listen your stories created on
          MakeMyTale.
        </h3>

        <div className="md:w-572px w-90per mx-auto md:h-657px h-5/6 bg-white md:px-15.5 px-3 rounded-xl ">
          <div className="flex flex-col  justify-center font-PoppinsMedium text-white">
            <button
              onClick={handleOAuthSignIn}
              className="md:w-440px w-auto h-12.2 rounded-10  bg-blue-20 mt-12 flex justify-between  items-center"
            >
              <div className="w-20 pl-3">
                <img className="" src="/images/google-icon.svg" alt="" />
              </div>
              <div className="font-PoppinsMedium md:text-ft17-26 text-ft14-21 whitespace-nowrap">
                {isSignUp ? (
                  <BiLoaderAlt className="w-8 h-8 animate-spin" />
                ) : (
                  "Sign up with Google"
                )}
              </div>
              <div className="w-15"></div>
            </button>
          </div>
          <div className="flex items-center mt-7 mb-8  ">
            <div className="h-px  bg-gray-40 md:w-48 w-32 ml-auto"></div>
            <div className=" mx-2 font-PoppinsMedium text-gray-40">OR</div>
            <div className="h-px bg-gray-40 md:w-48 w-32 mr-auto"></div>
          </div>
          <div className="flex justify-between gap-1  mb-7 ">
            <div className="h-12.5 border flex flex-col border-gray-50 rounded-10 w-3/6 md:w-52.5">
              <input
                type="text"
                placeholder="First name"
                className=" text-ft4 bg-transparent remove-prefill-autofill rounded-10 py-3 px-3 md:px-5 placeholder-gray-60 text-black-0 font-PoppinsMedium focus:outline-none "
                {...register("firstName", {
                  required: "Please enter first name.",
                })}
                autoComplete="new-password"
              ></input>
              {!!errors.firstName && (
                <div className="text-red-20 font-PoppinsRegular pl-2 md:text-ft15-21 text-ft11-18 mt-1">
                  {errors.firstName.message}
                </div>
              )}
            </div>

            <div className="h-12.5 flex flex-col border border-gray-50 w-3/6 md:w-52.5 rounded-10">
              <input
                type="text"
                placeholder="Last name"
                className=" text-ft4 bg-transparent remove-prefill-autofill rounded-10 py-3 md:px-5 px-3 h-12.5 placeholder-gray-60 text-black-0 font-PoppinsMedium focus:outline-none"
                {...register("lastName", {
                  required: "Please enter last name.",
                })}
                autoComplete="new-password"
              ></input>
              {!!errors.lastName && (
                <div className="text-red-20 font-PoppinsRegular pl-2 md:text-ft15-21 text-ft11-18 whitespace-nowrap mt-1">
                  {errors.lastName.message}
                </div>
              )}
            </div>
          </div>

          <div className="md:w-440px flex-col w-auto mx-auto flex h-12.5 border border-gray-50 rounded-10 ">
            <input
              type="email"
              {...register("email", {
                required: EMAIL_REQUIRED,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: INVALID_EMAIL,
                },
              })}
              onChange={setSignUpError}
              autoComplete="new-password"
              placeholder="Email Address"
              className=" text-ft4 bg-transparent remove-prefill-autofill rounded-10 py-3 md:px-5 px-3 placeholder-gray-60 text-black-0 font-PoppinsMedium focus:outline-none w-full"
            ></input>
            {!!signUpError.length > 0 ? (
              <div className="font-PoppinsRegular text-red-20 flex  pl-2 md:text-ft15-21 text-ft11-18 mt-1">
                {signUpError}
              </div>
            ) : (
              ""
            )}
            {!!errors.email && (
              <div className="text-red-20 font-PoppinsRegular pl-2 md:text-ft15-21 text-ft11-18 mt-1">
                {errors.email.message}
              </div>
            )}
          </div>
          <div className="flex flex-col w-auto ">
            <div className="md:w-440px flex justify-between  h-12.5 border border-gray-50 rounded-10 mt-7">
              <input
                {...register("password", {
                  pattern: {
                    value:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    message: PASSWORD_ALPHA_NUMBER_ERROR,
                  },
                  minLength: {
                    value: 8,
                    message: PASSWORD_MUST_8_CHARACTERS,
                  },
                  required: PASSWORD_REQUIRED,
                })}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                autoComplete="new-password"
                className=" text-ft4 bg-transparent remove-prefill-autofill rounded-10 py-3 md:px-5 px-3 placeholder-gray-60 text-black-0 font-PoppinsMedium focus:outline-none w-4/5"
              ></input>
              <div
                className="cursor-pointer mr-4.5 mt-3 "
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <AiOutlineEye className="w-6 h-6" />
                ) : (
                  <AiOutlineEyeInvisible className="w-6 h-6" />
                )}
              </div>
            </div>
            {!!errors.password && (
              <div className="text-red-20 font-PoppinsRegular pl-2 md:text-ft15-21 text-ft11-18 mt-1 ">
                {errors.password.message}
              </div>
            )}
          </div>

          <div className="flex  mt-9 ">
            <input
              type="checkbox"
              className="w-5 h-5 ml-2"
              {...register("accepted", {
                required: "Please accept terms of service and privacy policy",
              })}
              // onChange={(ev) => ev.target.checked}
            />
            <span className="font-PoppinsMedium flex-wrap text-ft16-22 text-gray-10 pl-2.5 ">
              <span className="whitespace-nowrap">I accept the</span>
              <span className="text-black-10 mx-1 whitespace-nowrap">
                <Link to="/terms-service">Terms of Service</Link>
              </span>{" "}
              and
              <span className="text-black-10 mx-2 whitespace-nowrap">
                <Link to="/privacy-policy">privacy Policy</Link>
              </span>
            </span>
          </div>
          {!!errors.accepted && (
            <div className="text-red-20 font-PoppinsRegular pl-2 md:text-ft15-21 text-ft11-18 mt-1">
              {errors.accepted.message}
            </div>
          )}

          <button
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            className="md:w-440px w-3/4 h-12.2 mx-auto font-PoppinsMedium rounded-10 bg-red-10 mt-7 text-white mt-7.75S flex justify-center  items-center"
          >
            {isLoading ? (
              <BiLoaderAlt className="w-8 h-8 animate-spin" />
            ) : (
              "Sign Up"
            )}
          </button>

          <p className=" text-ft4 font-OpenSansRegular flex justify-center items-center pt-7.5 pb-12.5">
            <Link to="/login" className="font-PoppinsMedium text-gray-10 ">
              Already have an account?
              <span className="font-PoppinsMedium text-red-10 ml-1 text-ft17-26">
                Sign In
              </span>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default CreateAccount;
