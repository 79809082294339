import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { sessionManager } from "../../managers/sessionManager";
import CreateSubscription from "../../services/subscriptionService";
import { errorMessagesConstants, actionTypeConstants } from "../../constants";
import Utils from "../../utility";
import {
  Auth0Service,
  UserService,
  StoryService,
  SubscriptionService,
} from "../../services";
import { history } from "../../managers/history";

const {
  EMAIL_REQUIRED,
  PASSWORD_REQUIRED,
  INVALID_EMAIL,
} = errorMessagesConstants;

const { UPDATE_USER, SET_USER_PLAN } = actionTypeConstants;

function LoginComponent() {
  const [isLoading, setIsloading] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signInError, setSignInError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const saveUserHandler = async (data) => {
    try {
      await new UserService().saveUser(data);
    } catch (err) {}
  };

  const getUserPlan = async (userId) => {
    try {
      return await new SubscriptionService().getSubscribedPlans({
        userId,
      });
    } catch {
      return {};
    }
  };
  const getPlans = async () => {
    try {
      return await new CreateSubscription().getPlans();
    } catch {
      return {};
    }
  };

  const SubscriptionPlan = async (userId, planId, planType) => {
    try {
      return await new CreateSubscription().subscribePlan({
        userId,
        planId,
        planType,
      });
    } catch {
      return {};
    }
  };
  

  const redirectToLink = (planType) => {
    const redirectLink = sessionManager.getDataFromCookies("REDIRECT_LINK");
    if (redirectLink) {
      sessionManager.removeDataFromCookies("REDIRECT_LINK");
      if (redirectLink === "/story-book/home" && planType === "paid") {
        history.push("/story-book/create-book");
      } else {
        history.push(redirectLink);
      }
    } else {
      history.push("/");
    }
  };

  const onSubmit = async (data) => {
    setSignInError("");
    setIsloading(true);
    try {
      const response = await new Auth0Service().signIn(data);
      const userData = {
        name: response.userInfoRes.nickname,
        firstName: response.userInfoRes.given_name,
        lastName: response.userInfoRes.family_name,
        userId: response.userInfoRes.sub,
        email: response.userInfoRes.email,
        profilePicture: response.userInfoRes.picture,
      };
      await saveUserHandler(userData);
      let [userDetails, userPlan] = await Promise.all([
        new UserService().getUserData({
          userId: response.userInfoRes.sub,
        }),
        getUserPlan(response.userInfoRes.sub),
      ]);
      dispatch({
        type: UPDATE_USER,
        payload: {
          ...userData,
          profilePicture: !!userDetails.profilePicture
            ? userDetails.profilePicture
            : response.userInfoRes.picture,
          userIntro: userDetails?.userIntro || "",
          _id: userDetails?._id,
          role: userDetails?.role,
        },
      });

      if (
        userPlan?.status === "NO_VALID_PLAN" ||
        userPlan?.status === "EXPIRED"
      ) {
        const plans = await getPlans();
        const planIndex = plans.findIndex((plan) => plan.planType === "Free");
        userPlan = await SubscriptionPlan(
          response.userInfoRes.sub,
          plans[planIndex]._id,
          plans[planIndex].planType
        );
      }

      const storyConfig = sessionManager.getDataFromCookies("story_config");
      if (!storyConfig) {
        if (userPlan?.status === "EXPIRED") {
          dispatch({
            type: SET_USER_PLAN,
            payload: {
              hasPlan: false,
              expired: true,
              isLoading: false,
              planDetails: {},
            },
          });
        } else {
          dispatch({
            type: SET_USER_PLAN,
            payload: {
              hasPlan: true,
              expired: false,
              isLoading: false,
              planDetails: userPlan,
            },
          });
        }
        redirectToLink(userPlan?.planType?.toLowerCase());
        return;
      }
      checkPlanStatus({ storyConfig, subscriptionPlan: userPlan, userDetails });
    } catch (error) {
      console.log(error);
      setSignInError(error.description);
    } finally {
      setIsloading(false);
    }
  };

  const createStory = async (request, userDetails) => {
    try {
      const query = new URLSearchParams(request).toString();
      const [[story], createRes] = await Promise.all([
        new StoryService().getStory(query),
        new StoryService().createStory({
          ...request,
          userId: userDetails.userId,
          characterNamedAs: request.name,
        }),
      ]);
      if (!story || !story?.storyParagraphs || !createRes?.amqResponse)
        throw new Error("");
      const paragraphs = Utils.organizeParagraph(
        story.storyParagraphs,
        story.images
      );
      dispatch({
        type: "UPDATE_STORY",
        payload: {
          paragraphs,
          ...story,
          storyConfig: {
            ...request,
            fetchData: true,
            storyId: createRes?.storyId,
          },
        },
      });

      Utils.navigateToStory(story?._id, story?.storyTitle);
    } catch (error) {
      Utils.failureToast(error?.message, {
        className: "font-PoppinsMedium",
        style: {
          borderRadius: "16px",
          height: "68px",
          padding: "23px 22px 22px 18px",
        },
      });
      console.log("error: ", error);
      setTimeout(() => {
        history.push("/");
      }, 1500);
    } finally {
      sessionManager.removeDataFromCookies("story_config");
    }
  };

  function checkPlanStatus({ storyConfig, subscriptionPlan, userDetails }) {
    if (subscriptionPlan?.status === "NO_VALID_PLAN") {
      history.push("/subscription-plan");
      dispatch({
        type: SET_USER_PLAN,
        payload: { hasPlan: false, expired: false, planDetails: {} },
      });
      return;
    }
    if (subscriptionPlan?.status === "EXPIRED") {
      dispatch({
        type: SET_USER_PLAN,
        payload: { hasPlan: false, expired: true, planDetails: {} },
      });
      Utils.failureToast(
        "Your plan has been expired, Please upgrade your plan!",
        {
          className: "font-PoppinsMedium",
          style: {
            borderRadius: "16px",
            height: "68px",
            padding: "23px 22px 22px 18px",
          },
        }
      );
      setTimeout(() => {
        history.push("/subscription-plan");
      }, 1500);
      return;
    }

    const isUnlimited =
      String(subscriptionPlan?.featureBalances?.AIStory)?.toLowerCase() ===
      "unlimited";
    if (isUnlimited) {
      createStory(storyConfig, userDetails);
      dispatch({
        type: SET_USER_PLAN,
        payload: {
          hasPlan: true,
          expired: false,
          planDetails: subscriptionPlan,
        },
      });
      return;
    }

    if (
      Number(subscriptionPlan?.featureBalances?.AIStory) < 1 &&
      Number(subscriptionPlan?.dailyCredits.AIStory) < 1
    ) {
      Utils.failureToast(errorMessagesConstants.LIMIT_EXCEEDED, {
        className: "font-PoppinsMedium",
        style: {
          borderRadius: "16px",
          height: "68px",
          padding: "23px 22px 22px 18px",
        },
      });
      dispatch({
        type: SET_USER_PLAN,
        payload: {
          hasPlan: true,
          expired: false,
          planDetails: subscriptionPlan,
        },
      });
      setTimeout(() => {
        history.push("/subscription-plan");
      }, 1500);
      return;
    }
    createStory(storyConfig, userDetails);

    const planDeductedBalance =
      Number(subscriptionPlan?.dailyCredits?.AIStory) < 1
        ? {
            ...subscriptionPlan,
            featureBalances: {
              ...subscriptionPlan?.featureBalances,
              AIStory:
                Number(subscriptionPlan?.featureBalances?.AIStory || 0) - 1,
              audioBook:
                Number(subscriptionPlan?.featureBalances?.audioBook || 0) - 1,
            },
          }
        : {
            ...subscriptionPlan,
            dailyCredits: {
              ...subscriptionPlan?.dailyCredits,
              AIStory: Number(subscriptionPlan?.dailyCredits?.AIStory || 0) - 1,
              audioBook:
                Number(subscriptionPlan?.dailyCredits?.audioBook || 0) - 1,
            },
          };

    dispatch({
      type: SET_USER_PLAN,
      payload: {
        hasPlan: true,
        expired: false,
        planDetails: planDeductedBalance,
      },
    });
  }

  const handleOAuthSignIn = () => {
    new Auth0Service().oAuthSignIn();
    setIsSigning(true);
  };

  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15  mx-auto  pb-14 ">
        <h1 className="sm:text-ft46-50 text-ft30-37  items-center text-center font-TimesBold mb-2 text-white">
          Sign in to MakeMyTale
        </h1>
        <h3 className="sm:text-ft17-26 text-ft1 lg:px-40 md:px-24 px-12  max-w-310 mx-auto font-PoppinsMedium pb-11.5  text-white">
          Save, share and listen your stories created on MakeMyTale.
        </h3>

        <div className="md:w-572px md:h-556px w-90per  bg-white mx-auto rounded-xl md:px-15.5 px-3 ">
          <div className="flex flex-col mx-auto  justify-center font-PoppinsMedium text-white">
            {/* <div className="md:w-440px w-3/4 mx-auto  h-12.2 rounded-10 bg-blue-10 mt-10 flex justify-between  items-center">
              <div className="w-20 pl-3">
                <img
                  className=""
                  src="/images/facebook-signup-icon.svg"
                  alt=""
                />
              </div>
              <div className="font-PoppinsMedium md:text-ft17-26 text-ft14-21 whitespace-nowrap ">
                Sign in with Facebook
              </div>
              <div className="w-15"></div>
            </div> */}
            <button
              onClick={handleOAuthSignIn}
              className="md:w-440px w-auto  h-12.2 rounded-10 bg-blue-20 mt-12 flex justify-between  items-center"
            >
              <div className="w-20 pl-3">
                <img className="" src="/images/google-icon.svg" alt="" />
              </div>
              <div className="font-PoppinsMedium md:text-ft17-26 text-ft14-21 whitespace-nowrap">
                {isSigning ? (
                  <BiLoaderAlt className="w-8 h-8 animate-spin" />
                ) : (
                  "Sign in with Google"
                )}
              </div>
              <div className="w-15"></div>
            </button>
          </div>
          <div className="flex items-center mt-7 mb-8  ">
            <div className="h-px  bg-gray-40 md:w-48 w-32 ml-auto"></div>
            <div className=" mx-2 font-PoppinsMedium text-gray-40">OR</div>
            <div className="h-px bg-gray-40 md:w-48 w-32 mr-auto"></div>
          </div>

          <div className="md:w-440px w-auto h-13 border border-gray-50 rounded-10 ">
            <Controller
              render={({ field }) => (
                <input
                  type="email"
                  onBlur={field.onBlur}
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                  placeholder="Email Address"
                  className="text-ft4 bg-transparent remove-prefill-autofill rounded-10 py-3 px-5 md:px-5 placeholder-gray-60 text-black-0 font-PoppinsMedium focus:outline-none w-full"
                />
              )}
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: EMAIL_REQUIRED,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: INVALID_EMAIL,
                },
              }}
            />
          </div>
          {!!errors.email && (
            <div className="font-PoppinsRegular text-red-20 pl-2 w-1/2 whitespace-nowrap  md:text-ft15-21 text-ft11-18 mt-1">
              {errors.email.message}
            </div>
          )}
          <div className="flex flex-col w-auto ">
            <div className="  md:w-440px w-auto flex justify-between h-12.5 border border-gray-50 rounded-10 mt-7 ">
              <Controller
                render={({ field }) => (
                  <input
                    type={showPassword ? "text" : "password"}
                    onBlur={field.onBlur}
                    value={field.value}
                    onChange={field.onChange}
                    ref={field.ref}
                    placeholder="Password"
                    className=" text-ft4 bg-transparent rounded-10 py-3 remove-prefill-autofill px-5 md:px-5 placeholder-gray-60 text-black-0 font-PoppinsMedium focus:outline-none w-4/5"
                  />
                )}
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: PASSWORD_REQUIRED,
                }}
              />
              <div
                className="cursor-pointer mr-4.5 mt-3"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <AiOutlineEye className="w-6 h-6" />
                ) : (
                  <AiOutlineEyeInvisible className="w-6 h-6" />
                )}
              </div>
            </div>
          </div>
          {!!errors.password && (
            <div className="font-PoppinsRegular text-red-20 pl-2  md:text-ft15-21 text-ft11-18 mt-1">
              {errors.password.message}
            </div>
          )}
          <Link
            className="flex  mt-1 text-right justify-end  font-PoppinsMedium text-ft16-22 text-gray-10"
            to="/forgot-password"
          >
            Forgot password?
          </Link>
          <button
            onClick={handleSubmit(onSubmit)}
            className="  h-13 md:w-440px mx-auto w-3/4 rounded-10 mt-10 font-PoppinsMedium bg-red-10 hover:bg-red-30 text-white mt-7.75S flex justify-center  items-center"
          >
            {isLoading ? (
              <BiLoaderAlt className="w-8 h-8 animate-spin" />
            ) : (
              "Sign In"
            )}
          </button>
          {!!signInError.length > 0 ? (
            <div className="font-PoppinsRegular text-red-20 flex justify-center pl-2 md:text-ft15-21 text-ft11-18 mt-1">
              {signInError}
            </div>
          ) : (
            ""
          )}
          <p className=" text-ft4 font-OpenSansRegular flex justify-center items-center pt-7.5 pb-12.5">
            <Link to="/signup" className="font-PoppinsMedium text-gray-10 ">
              New User?
              <span className="font-PoppinsMedium text-red-10 ml-1">
                Sign Up
              </span>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default LoginComponent;
