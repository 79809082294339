import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Switch, Route, Redirect } from "react-router-dom";
import { history } from "../../managers/history";
import { SubscriptionService, StoryService } from "../../services";
import Utils from "../../utility";
import { actionTypeConstants, errorMessagesConstants } from "../../constants";
import BlurLoadingScreen from "../../common/components/blurLoadingScreen";
import { sessionManager } from "../../managers/sessionManager";
import PlanCards from "./planCards";
import PaymentScreen from "./paymentScreen";

const { SET_USER_PLAN } = actionTypeConstants;

function SubscribePlan() {
  const [loading, setLoading] = useState(true);
  const [seletedPlan] = useState({});
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [subcriptionPlans, setSubcriptionPlans] = useState({
    hasPlan: false,
    plans: { monthly: [], yearly: [] },
  });
  const [checkedPlanType, setCheckPlanType] = useState("monthly");

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);

  const handleSubscribePlan = async (data) => {
    setPaymentLoading(true);
    try {
      const userPlan = await new SubscriptionService().subscribePlan(data);

      const storyConfig = sessionManager.getDataFromCookies("story_config");
      if (!storyConfig) {
        dispatch({
          type: SET_USER_PLAN,
          payload: {
            hasPlan: true,
            expired: false,
            isLoading: false,
            planDetails: userPlan,
          },
        });
        history.push("/");
        return;
      }
      checkPlanStatus({ storyConfig, subscriptionPlan: userPlan });
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setPaymentLoading(false);
    }
  };

  const onSubscribe = async (plan) => {
    try {
      if (plan.planType.toLowerCase() === "free") {
        setPaymentLoading(true);
        await handleSubscribePlan({
          planId: plan?._id,
          planType: plan?.planType,
          subscriptionId: "",
          price: 0,
          subscriptionDetails: {},
          billingDetails: {},
        });
      } else {
        const userId = user.userId.replace("|", "_");
        window.open(
          `${plan.paymentLink}?client_reference_id=${userId}_AND_${plan._id}`
        );
      }
    } catch (err) {
      setPaymentLoading(false);
      Utils.failureToast(
        "An error occured creating subscription, Please try again"
      );
      console.log("err: ", err);
    }
  };

  const createStory = async (request) => {
    try {
      const query = new URLSearchParams(request).toString();
      const [[story], createRes] = await Promise.all([
        new StoryService().getStory(query),
        new StoryService().createStory({
          ...request,
          userId: user.userId,
          characterNamedAs: request.name,
        }),
      ]);
      if (!story || !story?.storyParagraphs || !createRes?.amqResponse)
        throw new Error("");
      const paragraphs = Utils.organizeParagraph(
        story.storyParagraphs,
        story.images
      );
      dispatch({
        type: "UPDATE_STORY",
        payload: {
          paragraphs,
          ...story,
          storyConfig: {
            ...request,
            fetchData: true,
            storyId: createRes?.storyId,
          },
        },
      });
      Utils.navigateToStory(story?._id, story?.storyTitle);
    } catch (error) {
      Utils.failureToast(error?.message);
      console.log("error: ", error);
      setTimeout(() => {
        history.push("/");
      }, 1500);
    } finally {
      sessionManager.removeDataFromCookies("story_config");
    }
  };

  function checkPlanStatus({ storyConfig, subscriptionPlan }) {
    const isUnlimited =
      String(subscriptionPlan?.featureBalances?.AIStory)?.toLowerCase() ===
      "unlimited";
    if (isUnlimited) {
      createStory(storyConfig);
      dispatch({
        type: SET_USER_PLAN,
        payload: {
          hasPlan: true,
          expired: false,
          isLoading: false,
          planDetails: subscriptionPlan,
        },
      });
      return;
    }

    if (
      Number(subscriptionPlan?.featureBalances?.AIStory) < 1 &&
      Number(subscriptionPlan?.dailyCredits?.AIStory) < 1
    ) {
      Utils.failureToast(errorMessagesConstants.LIMIT_EXCEEDED);
      dispatch({
        type: SET_USER_PLAN,
        payload: {
          hasPlan: true,
          expired: false,
          isLoading: false,
          planDetails: subscriptionPlan,
        },
      });
      setTimeout(() => {
        history.push("/subscription-plan");
      }, 1500);
      return;
    }
    createStory(storyConfig);

    const planDeductedBalance =
      Number(subscriptionPlan?.dailyCredits?.AIStory) < 1
        ? {
            ...subscriptionPlan,
            featureBalances: {
              ...subscriptionPlan?.featureBalances,
              AIStory:
                Number(subscriptionPlan?.featureBalances?.AIStory || 0) - 1,
              audioBook:
                Number(subscriptionPlan?.featureBalances?.audioBook || 0) - 1,
            },
          }
        : {
            ...subscriptionPlan,
            dailyCredits: {
              ...subscriptionPlan?.dailyCredits,
              AIStory: Number(subscriptionPlan?.dailyCredits?.AIStory || 0) - 1,
              audioBook:
                Number(subscriptionPlan?.dailyCredits?.audioBook || 0) - 1,
            },
          };

    dispatch({
      type: SET_USER_PLAN,
      payload: {
        hasPlan: true,
        expired: false,
        isLoading: false,
        planDetails: planDeductedBalance,
      },
    });
  }

  const getSubscribed = async () => {
    if (!user.isLoggedIn) return;
    try {
      return await new SubscriptionService().getSubscribedPlans({
        userId: user.userId,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const separatePlans = (plansData) => {
   return plansData.reduce(
      (prev, curt) => {
        if (curt.planType.toLowerCase() === "free") {
          prev.monthly.push(curt);
          prev.yearly.push(curt);
        } else if (curt.duration === "month") {
          prev.monthly.push(curt);
        } else if (curt.duration === "year") {
          prev.yearly.push(curt);
        }
        return prev;
      },
      { monthly: [], yearly: [] }
    );
  };

  const orderPlans = (plans, userPlan) => {
    const planObject = { plans: { monthly: [], yearly: [] }, hasPlan: false };
    let planNumber = 0;

    const pushToPlan = (data) => {
      let monthlyPlan = plans.find((plan) => plan.duration === "month");
      if (data.planType.toLowerCase() === "free") {
        planObject.plans.monthly.push(data);
        planObject.plans.yearly.push(data);
      } else if (data.duration === "month") {
        planObject.plans.monthly.push(data);
      } else if (data.duration === "year") {
        planObject.plans.yearly.push(data);
        planObject.plans.monthly.push(monthlyPlan);
      }
    };
    // eslint-disable-next-line 
    for (const plan of plans) {
      if (plan?._id === userPlan?.planId) {
        planObject.hasPlan = true;
        planNumber = plan?.planNumber;
        if (plan?.duration === "year") {
          setCheckPlanType("yearly");
        }
        pushToPlan({ ...plan, currentPlan: true });
      } else {
        planNumber = plan?.planNumber;
        pushToPlan({ ...plan, currentPlan: false });
      }
    }
    if (planNumber !== null || planNumber !== 0) {
      planObject.plans.monthly = planObject.plans.monthly.filter(
        (i) => i.planNumber >= planNumber
      );
      planObject.plans.yearly = planObject.plans.yearly.filter(
        (i) => i.planNumber >= planNumber
      );
    }
    return planObject;
  };

  useEffect(() => {
    (async () => {
      if (paymentLoading) return;
      try {
        setLoading(true);
        const [plans, userPlan] = await Promise.all([
          new SubscriptionService().getPlans(),
          getSubscribed(),
        ]);
        if (
          !userPlan ||
          userPlan?.status === "EXPIRED" ||
          userPlan?.status === "NO_VALID_PLAN"
        ) {
          const plansData = separatePlans(plans);
          setSubcriptionPlans((p) => ({ ...p, plans: plansData }));
        } else {
          const data = orderPlans(plans, userPlan);
          setSubcriptionPlans(data);
        }
      } catch (error) {
        console.log("get plans and user plan error: ", error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [paymentLoading]);

  const handleOnCheck = () => {
    setCheckPlanType((prev) => (prev === "yearly" ? "monthly" : "yearly"));
  };

  return (
    <div className="bg-black-0">
      <Elements
        options={{
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap",
            },
          ],
          appearance: {
            theme: "flat",
            variables: {
              fontFamily: "Poppins",
              colorBackground: "#222222",
              fontSizeBase: "17px",
              fontSizeSm: "17px",
              colorTextPlaceholder: "#6C6C6C",
              colorText: "white",
              borderRadius: "30px",
              focusBoxShadow: "#6C6C6C",
              focusOutline: "#6C6C6C",
              spacingGridRow: "28px",
            },
            rules: {
              ".Input": {
                border: "1px solid #6C6C6C",
              },
              ".Input:focus": {
                border: "1px solid #ffffff",
              },
              ".Label": {
                color: "#6C6C6C",
              },
            },
          },
        }}
        stripe={stripePromise}
      >
        <Switch>
          <Route
            path="/subscription-plan"
            exact
            render={() => (
              <PlanCards
                handleOnCheck={handleOnCheck}
                onSubscribe={onSubscribe}
                subcriptionPlans={subcriptionPlans}
                checkedPlanType={checkedPlanType}
                loading={loading}
              />
            )}
          />
          <Route
            path="/subscription-plan/payment"
            exact
            render={() => (
              <PaymentScreen
                isLoading={paymentLoading}
                plan={seletedPlan}
                handleSubscribePlan={handleSubscribePlan}
              />
            )}
          />
          <Redirect exact from="*" to="/subscription-plan" />
        </Switch>
      </Elements>
      {paymentLoading && <BlurLoadingScreen />}
    </div>
  );
}

export default SubscribePlan;
